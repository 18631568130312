<template>
  <div>
    <form @submit.prevent="onSearch">
      <CRow class="align-items-end">
        <CCol md="3">
          <CInput
            :label="$t('family.market.showcase.filters.search')"
            :value="search"
            @update:value="onSearchChange"
            :placeholder="$t('family.market.showcase.filters.search_es')"
          >
            <template #prepend-content>
              <font-awesome-icon icon="search" />
            </template>
          </CInput>
        </CCol>
        <CCol md="3">
          <UiSelect
            :label="$t('family.market.showcase.filters.category')"
            :value="categoryId"
            :options="categoryOptions"
            @update:value="onCategorySelected"
            custom
          />
        </CCol>
        <CCol md="2">
          <CSelect
            :label="$t('family.market.showcase.filters.producer')"
            :value="producerId"
            :options="producerOptions"
            @update:value="onProducerSelected"
            custom
          />
        </CCol>
        <CCol md="2">
          <div class="form-group">
            <label>{{ $t('family.market.showcase.filters.certification') }}</label>
            <vSelect
              multiple
              :value="certificationsIds"
              :options="certificationsOptions"
              @input="onCertificationSelected"
            />
          </div>
        </CCol>
        <CCol col="auto">
          <CButton class="mb-3" color="primary" type="submit">
            <font-awesome-icon icon="search" />
          </CButton>
        </CCol>
        <CCol col="auto" class="ml-auto">
          <CButtonGroup class="mb-3">
            <CButton
              color="primary"
              :variant="viewType !== 'grid' ? 'outline' : ''"
              @click.prevent="changeViewType('grid')"
            >
              <font-awesome-icon icon="th" />
            </CButton>
            <CButton
              color="primary"
              :variant="viewType !== 'table' ? 'outline' : ''"
              @click.prevent="changeViewType('table')"
            >
              <font-awesome-icon icon="list" />
            </CButton>
          </CButtonGroup>
        </CCol>
      </CRow>
    </form>
  </div>
</template>

<script>
import UiSelect from "../../../common/form/UiSelect";
import vSelect from "vue-select";

export default {
  name: "MarketShowcaseFilters",

  components: {
    UiSelect,
    vSelect,
  },

  props: {
    viewType: { type: String, required: true },
    search: { type: String, default: "" },
    categoryId: { type: [String, Object, Number], default: null },
    categoryOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    producerId: { type: [String, Object, Number], default: null },
    producerOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    certificationsIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    certificationsOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  methods: {
    changeViewType(value) {
      this.$emit("update:viewType", value);
    },
    onSearchChange(value) {
      this.$emit("update:search", value);
    },
    onProducerSelected(value) {
      this.$emit("update:producerId", value);
    },
    onCategorySelected(value) {
      this.$emit("update:categoryId", value);
    },
    onCertificationSelected(value) {
      this.$emit("update:certificationsIds", value);
    },
    onSearch() {
      const filter = {
        viewType: this.viewType,
        search: this.search,
        categoryId: this.categoryId,
        producerId: this.producerId,
        certificationsIds: this.certificationsIds,
      };
      const payload = { filter: filter };
      this.$emit("onSearch", payload);
    },
  },
};
</script>
